import axios from 'axios';

export async function getUserInfo(userId){
  try {

    const response = await axios.get(`/settings/getUserInfo/${userId}`);
    return (response.data);
    
  } catch (err) {
    console.log(err);
  }
}

export async function getCustomerInfo(customerId){
  try {

    const response = await axios.get(`/settings/getCustomerInfo/${customerId}`);
    return (response.data);
    
  } catch (err) {
    console.log(err);
  }
}

export async function getUsers(customerId){
  try {

    const response = await axios.get(`/settings//getCustomerUsers/${customerId}`);
    return (response.data);
    
  } catch (err) {
    console.log(err);
  }
}

export async function getPermissions(customerId){
  try {

    const response = await axios.get(`/settings/getPermissions`);
    return (response.data);
    
  } catch (err) {
    console.log(err);
  }
}

export async function getRoles(customerId){
  try {

    const response = await axios.get(`/settings/getRoles`);
    return (response.data);
    
  } catch (err) {
    console.log(err);
  }
}


export async function getUserNotifications(userId){
  try {

    const response = await axios.get(`/settings/getNotificationInfo/${userId}`);
    return (response.data);
    
  } catch (err) {
    console.log(err);
  }
}




