import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import hero from '../../assets/login-hero.png';
import FailDialog from '../../components/dialogs/FailDialog';

const Invite = ({ setIsAuthenticated }) => {
  const { customer_id, token, role } = useParams();
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    password: ''
  });

  const [error, setError] = useState(false);

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Submitting:', { customer_id, token, role, inputs });
      const response = await axios.post(`/settings/register/${customer_id}/${token}/${role}`, inputs);
      console.log('Response:', response);
      localStorage.setItem('token', response.data.token);
      setIsAuthenticated(true);
    } catch (err) {
      console.error('Error:', err.message);
      setError(true);
    }
  }

  return (
    <div className='grid grid-cols-2 h-screen'>
      <div className='flex items-center justify-center p-10 bg-white'>
        <div className='mx-auto max-w-sm'>
          <div className='flex flex-col pb-7 mb-6 gap-y-5 text-center divide-rebin'>
            <h1 className='text-3xl font-medium'>Create your account</h1>
            <h3 className='text-sm font-light text-rebin-grey'>Unlock our smart asset features.</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <label htmlFor="name" className='block mb-2 text-base'>Full name</label>
            <input type='text' required name='name' autoComplete='off' className='block px-2 py-4 mb-5 w-full text-base font-light text-rebin-darkgrey rounded-lg border-rebin' value={inputs.name} onChange={handleChange} />
            <label htmlFor="email" className='block mb-2 text-base'>Email address</label>
            <input type='email' required name='email' autoComplete='off' className='block px-2 py-4 mb-5 w-full text-base font-light text-rebin-darkgrey rounded-lg border-rebin' value={inputs.email} onChange={handleChange} />
            <label htmlFor="password" className='block mb-2 text-base'>Password</label>
            <input type='password' required name='password' autoComplete='off' className='block px-2 py-4 mb-10 w-full rebin-password rounded-lg border-rebin' value={inputs.password} onChange={handleChange} />
            <button className='block rounded-lg px-2 py-4 mb-10 w-full bg-rebin-blue text-white bg-rebin-hover'>Create account</button>
          </form>
        </div>
      </div>
      <div className='flex flex-col justify-between items-center pt-10 pb-20 bg-rebin-blue text-center'>
        <h2 className='text-3xl text-white'>Welcome to <span className='font-bold'>Repoint</span></h2>
        <img src={hero} className='w-8/12 h-auto'></img>
        <h3 className='text-2xl text-white font-semibold'>Monitor every asset.</h3>
      </div>
      <FailDialog error={error} setError={setError} title='Something went wrong' description='We could not create an account.'></FailDialog>
    </div>
  )
}

export default Invite;
