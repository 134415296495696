import { Dialog } from '@headlessui/react';
import { useState } from 'react';
import alert from '../../assets/check-icon.svg';

const SuccessDialog = ({title, description}) => {
  let [isOpen, setIsOpen] = useState(true)

  return (
    <Dialog
      as="div"
      className="fixed inset-0 overflow-y-auto z-50"
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <div className="text-center">
        <div className=" absolute -top-7 right-0 transform -translate-x-4 mt-12">
          <Dialog.Panel className="max-w-md overflow-hidden rounded-md bg-rebin-success py-2 px-4 pr-20 text-left align-middle shadow-xl">
            <div className='flex items-center'>
              <img src={alert} className='mr-4' />
              <div>
                <Dialog.Title as="h3" className="block text-base font-bold leading-6 text-alert-color">
                  {title}
                </Dialog.Title>
                <div className="block mt-1">
                  <p className="text-sm text-alert-color">
                    {description}
                  </p>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  )
}

export default SuccessDialog;