import { React, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import hero from '../../assets/login-hero.png'
import FailDialog from '../../components/dialogs/FailDialog'
import PasswordStrengthMeter from './components/PasswordStrengthMeter'

const Register = ({ setIsAuthenticated }) => {
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    password: '',
  })

  const [error, setError] = useState(false)

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post(
        '/auth/register',
        inputs,
      )
      localStorage.setItem('token', response.data.token)
      setIsAuthenticated(true)
    } catch (err) {
      console.log(err.message)
      setError(true)
    }
  }

  return (
    <div className='grid h-screen grid-cols-2'>
      <div className='flex items-center justify-center bg-white p-10'>
        <div className='mx-auto max-w-sm'>
          <div className='divide-rebin mb-6 flex flex-col gap-y-5 pb-7 text-center'>
            <h1 className='text-3xl font-medium'>Create your account</h1>
            <h3 className='text-sm font-light text-rebin-grey'>
              Unlock our smart waste features.
            </h3>
          </div>
          <form onSubmit={handleSubmit}>
            <label htmlFor='name' className='mb-2 block text-base'>
              Name
            </label>
            <input
              type='text'
              required
              name='name'
              autoComplete='off'
              className='text-rebin-darkgrey border-rebin mb-5 block w-full rounded-lg px-2 py-4 text-base font-light'
              value={inputs.name}
              onChange={handleChange}
            />
            <label htmlFor='email' className='mb-2 block text-base'>
              Email address
            </label>
            <input
              type='email'
              required
              name='email'
              autoComplete='off'
              className='text-rebin-darkgrey border-rebin mb-5 block w-full rounded-lg px-2 py-4 text-base font-light'
              value={inputs.email}
              onChange={handleChange}
            />
            <label htmlFor='password' className='mb-2 block text-base'>
              Password
            </label>
            <input
              type='password'
              required
              name='password'
              autoComplete='off'
              className='rebin-password border-rebin mb-2 block w-full rounded-lg px-2 py-4'
              value={inputs.password}
              onChange={handleChange}
            />
            <PasswordStrengthMeter password={inputs.password} />
            <button className='bg-rebin-hover mb-10 block w-full rounded-lg bg-rebin-blue px-2 py-4 text-white'>
              Create account
            </button>
            <span className='text-rebin-darkgrey block text-center text-base font-light'>
              You have an account?{' '}
              <Link to='/' className='text-rebin-blue hover:font-medium'>
                Login now
              </Link>
            </span>
          </form>
        </div>
      </div>
      <div className='flex flex-col items-center justify-between bg-rebin-blue pb-20 pt-10 text-center'>
        <h2 className='text-3xl text-white'>
          Welcome to <span className='font-bold	'>Repoint</span>
        </h2>
        <img src={hero} className='h-auto w-8/12'></img>
        <h3 className='text-2xl font-semibold text-white'>
          Monitor every asset.
        </h3>
      </div>
      <FailDialog
        error={error}
        setError={setError}
        title='Something went wrong'
        description='We could not create an account.'
      ></FailDialog>
    </div>
  )
}

export default Register
