import axios from 'axios';

export async function createAsset(customerId, requestBody){
  try {

    const response = await axios.post(`/assets/addAsset/${customerId}`, requestBody);
    console.log(response);
    
  } catch (err) {
    console.log(err);
  }
}

export async function getAssets(customerId){
  try {

    const response = await axios.get(`/assets/getAssets/${customerId}`);
    return (response.data);
    
  } catch (err) {
    console.log(err);
  }
}

export async function getTotalAssets(customerId){
  try {

    const response = await axios.get(`/assets/getTotalAssets/${customerId}`);
    return (response.data);
    
  } catch (err) {
    console.log(err);
  }
}

export async function getAssetAddress(assetID){
  try {

    const response = await axios.get(`/assets/getAssetProperties/${assetID}`);
    console.log(response.data.asset_address);
    return (response.data.asset_address);
    
  } catch (err) {
    console.log(err);
  }
}

export async function getAssetProperties(assetID){
  try {

    const response = await axios.get(`/assets/getAssetProperties/${assetID}`);
    console.log(response.data);
    return response.data;
    
  } catch (err) {
    console.log(err);
  }
}

export async function getAssetTypes(customer_id){
  try {

    const response = await axios.get(`/assets/getAssetType/${customer_id}`);
    console.log(response.data);
    return response.data;
    
  } catch (err) {
    console.log(err);
  }
}

export async function getAssetContents(customer_id){
  try {

    const response = await axios.get(`/assets/getContent/${customer_id}`);
    console.log(response.data);
    return response.data;
    
  } catch (err) {
    console.log(err);
  }
}

export async function updateAsset(customer_id, asset_id, requestBody){
  try {

    const response = await axios.put(`/assets/updateAsset/${customer_id}/${asset_id}`, requestBody);
    console.log(response);
    
  } catch (err) {
    console.log(err);
  }
}

export async function getTimesCollected(customer_id, asset_id){
  try {

    const response = await axios.get(`/assets/getTimesCollected/${customer_id}/${asset_id}`);
    return response.data;
    console.log(response);
    
  } catch (err) {
    console.log(err);
  }
}



