import React, { useState } from 'react';

const CheckboxComponent = ({checked, onClick}) => {
  const [isChecked, setChecked] = useState(checked);

  const handleCheckboxChange = () => {
    setChecked(!isChecked);
  };

  return (
    <div>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        onClick={onClick}
      />
    </div>
  );
};

export default CheckboxComponent;
