import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Checkbox from '../../components/Checkbox';
import hero from '../../assets/login-hero.png';
import FailDialog from '../../components/dialogs/FailDialog';

const Login = ({ setIsAuthenticated }) => {
  const [inputs, setInputs] = useState({
    email: '',
    password: ''
  });

  const [error, setError] = useState('');

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/auth/login', inputs);
      localStorage.setItem('token', response.data.token);
      setIsAuthenticated(true);
    } catch (err) {
      console.log(err.message);
      setError('Invalid email or password. Please try again.'); // Set specific error message
    }
  };

  return (
    <div className='grid grid-cols-2 h-screen'>
      <div className='flex items-center justify-center p-10 bg-white'>
        <div className='mx-auto max-w-sm'>
          <div className='flex flex-col pb-7 mb-6 gap-y-5 text-center divide-rebin'>
            <h1 className='text-3xl font-medium'>Log in to your account</h1>
            <h3 className='text-sm font-light text-rebin-grey'>
              Welcome back! Please enter your details.
            </h3>
          </div>
          <form onSubmit={handleSubmit}>
            <label htmlFor='email' className='block mb-2 text-base'>
              Email address
            </label>
            <input
              type='email'
              name='email'
              autoComplete='off'
              className='block px-2 py-4 mb-5 w-full text-base font-light text-rebin-darkgrey rounded-lg border-rebin'
              value={inputs.email}
              onChange={handleChange}
              required
            />
            <label htmlFor='password' className='block mb-2 text-base'>
              Password
            </label>
            <input
              type='password'
              name='password'
              autoComplete='off'
              className='block px-2 py-4 mb-5 w-full rebin-password rounded-lg border-rebin'
              value={inputs.password}
              onChange={handleChange}
              required
            />
            <div className='flex justify-between items-center mb-6'>
              <div className='flex items-center gap-x-2'>
                <Checkbox></Checkbox>
                <span className='text-base font-light text-rebin-darkgrey'>
                  Remember me
                </span>
              </div>
              <Link
                to='/password'
                className='text-base font-light text-rebin-blue hover:font-medium'
              >
                Forgot password?
              </Link>
            </div>
            <button
              type='submit'
              className='block rounded-lg px-2 py-4 mb-10 w-full bg-rebin-blue text-white bg-rebin-hover'
            >
              Log in
            </button>
            <span className='block text-center text-base font-light text-rebin-darkgrey'>
              Don't have an account?{' '}
              <Link to='/register' className='text-rebin-blue hover:font-medium'>
                Create an account
              </Link>
            </span>
          </form>
        </div>
      </div>
      <div className='flex flex-col justify-between items-center pt-10 pb-20 bg-rebin-blue text-center'>
        <h2 className='text-3xl text-white'>
          Welcome to <span className='font-bold'>Repoint</span>
        </h2>
        <img src={hero} alt='Hero' className='w-8/12 h-auto'></img>
        <h3 className='text-2xl text-white font-semibold'>
          Monitor every asset.
        </h3>
      </div>
      {error && (
        <FailDialog
          error={true}
          setError={setError}
          title='Invalid login.'
          description={error}
        />
      )}
    </div>
  );
};

export default Login;
