import React from 'react'
import zxcvbn from 'zxcvbn'

const PasswordStrengthMeter = ({ password }) => {
  const testResult = zxcvbn(password)
  const num = (testResult.score * 100) / 4

  const createPassLabel = () => {
    switch (testResult.score) {
      case 0:
        return ''
      case 1:
        return 'Weak'
      case 2:
        return 'Okay'
      case 3:
        return 'Good'
      case 4:
        return 'Strong'
      default:
        return ''
    }
  }

  const funcProgressColor = () => {
    switch (testResult.score) {
      case 0:
        return '#2B3E71'
      case 1:
        return '#2B3E71'
      case 2:
        return '#1D40A0'
      case 3:
        return '#0E41D0'
      case 4:
        return '#0043FF'
      default:
        return 'none'
    }
  }

  const changePasswordColor = () => ({
    width: `${num}%`,
    background: funcProgressColor(),
    height: '7px',
  })

  return (
    <>
      <div className='progress'>
        <div className='progress-bar' style={changePasswordColor()}></div>
      </div>
      <p className='h-8' style={{ color: funcProgressColor() }}>
        {createPassLabel()}
      </p>
    </>
  )
}

export default PasswordStrengthMeter
