import { React, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IoMdCheckmark } from 'react-icons/io'
import axios from 'axios'
import { Link } from 'react-router-dom'

const ResetPassword = () => {
  const { user_id, token } = useParams()
  const [inputs, setInputs] = useState({
    newPassword: '',
    confirmPassword: '',
  })
  const [updated, setUpdated] = useState(false)

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      if (inputs.newPassword !== inputs.confirmPassword) {
        throw new Error('Passwords do not match')
      }
      const response = await axios.post(
        `/password/reset/${user_id}/${token}`,
        { password: inputs.newPassword },
      )
      if (response.data.update) {
        setUpdated(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className='grid h-screen grid-cols-2'>
      {updated ? (
        <div className='flex items-center justify-center bg-white p-10'>
          <div className='mx-auto max-w-sm'>
            <div className='mb-6 flex flex-col gap-y-5 pb-7 text-center'>
              <span className='mx-auto mb-4 flex h-20 w-20 items-center justify-center rounded-full border-solid border-rebin-blue p-4'>
                <IoMdCheckmark className='text-4xl text-rebin-blue' />
              </span>
              <h1 className='text-3xl font-medium'>Password changed</h1>
              <h3 className='text-sm font-light text-rebin-grey'>
                You can now use your new password to log in to your account.
              </h3>
            </div>
            <Link
              to='/login'
              className='bg-rebin-hover mb-10 block w-full rounded-lg bg-rebin-blue px-2 py-4 text-center text-white'
            >
              Log in
            </Link>
          </div>
        </div>
      ) : (
        <div className='flex items-center justify-center bg-white p-10'>
          <div className='mx-auto max-w-sm'>
            <div className='divide-rebin mb-6 flex flex-col gap-y-5 pb-7 text-center'>
              <h1 className='text-3xl font-medium'>Change your password</h1>
              <h3 className='text-sm font-light text-rebin-grey'>
                Enter a new password below to change
                <br />
                your current password.
              </h3>
            </div>
            <form onSubmit={handleSubmit}>
              <label htmlFor='newPassword' className='mb-2 block text-base'>
                New password
              </label>
              <input
                type='password'
                name='newPassword'
                autoComplete='off'
                className='rebin-password border-rebin mb-5 block w-full rounded-lg px-2 py-4'
                value={inputs.newPassword}
                onChange={handleChange}
              />
              <label htmlFor='confirmPassword' className='mb-2 block text-base'>
                Confirm password
              </label>
              <input
                type='password'
                name='confirmPassword'
                autoComplete='off'
                className='rebin-password border-rebin mb-10 block w-full rounded-lg px-2 py-4'
                value={inputs.confirmPassword}
                onChange={handleChange}
              />
              <button className='bg-rebin-hover mb-10 block w-full rounded-lg bg-rebin-blue px-2 py-4 text-white'>
                Change password
              </button>
            </form>
          </div>
        </div>
      )}
      <div className='bg-rebin-light-blue p-10 text-center'>
        <h2 className='text-3xl text-rebin-darkblue'>
          Welcome to <span className='font-bold'>Repoint</span>
        </h2>
      </div>
    </div>
  )
}

export default ResetPassword
