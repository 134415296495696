import React, { useState } from "react";
import axios from "axios";
import { Link, NavLink } from "react-router-dom";
import Rebin from "../assets/embleem@4x.png";
import { GrHomeRounded } from "react-icons/gr";
import { BiBarChartAlt2 } from "react-icons/bi";
import { LuTrash, LuLogOut } from "react-icons/lu";
import { RiSendPlaneLine } from "react-icons/ri";
import { FaRegUser } from "react-icons/fa";

const Navbar = ({ setIsAuthenticated, userInfo, userId }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
  };

  const handleStatistics = async () => {
    await axios.put(`/usage/updateGlobalStatisticsCount/${userId}`);
  }

  return (
    <section className={`bg-white fixed sticky inset-y-0 h-screen z-10 rounded-r-xl shadow-md transition-transform duration-300 ease-in-out ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} md:translate-x-0 hidden sm:block`}>
      <nav className="flex flex-col justify-between items-center py-10 w-24 h-full">
        <Link className="w-14 p-3 shadow-md rounded-lg" to="/application">
          <img src={Rebin} alt="Rebin" />
        </Link>
        {userInfo?.user_business_role === 'Planner' ? (
          <div className="flex flex-col items-center gap-y-8 sm:gap-y-16">
            <NavLink to="/application" className={({ isActive }) => isActive ? "text-rebin-blue" : ""}>
              <GrHomeRounded className="w-5 h-auto text-rebin-grey opacity-60 hover:opacity-100 text-rebin-blue-hover" />
            </NavLink>
            <NavLink to="/application/navigation" className={({ isActive }) => isActive ? "text-rebin-blue" : ""}>
              <RiSendPlaneLine className="w-5 h-auto text-rebin-grey opacity-60 hover:opacity-100 text-rebin-blue-hover" />
            </NavLink>
            <NavLink to="/application/overview" className={({ isActive }) => isActive ? "text-rebin-blue" : ""}>
              <LuTrash className="w-5 h-auto text-rebin-grey opacity-60 hover:opacity-100 text-rebin-blue-hover" />
            </NavLink>
            <NavLink to="/application/statistics" onClick={handleStatistics} className={({ isActive }) => isActive ? "text-rebin-blue" : ""}>
              <BiBarChartAlt2 className="w-5 h-auto text-rebin-grey opacity-60 hover:opacity-100 text-rebin-blue-hover" />
            </NavLink>
            <NavLink to="/application/settings" className={({ isActive }) => isActive ? "text-rebin-blue" : ""}>
              <FaRegUser className="w-5 h-auto text-rebin-grey opacity-60 hover:opacity-100 text-rebin-blue-hover" />
            </NavLink>
          </div>
        ) : null }
        <button onClick={handleLogout} className="text-rebin-blue hover:text-rebin-blue-hover focus:outline-none">
          <LuLogOut className="w-5 h-auto text-rebin-grey opacity-60 hover:opacity-100 text-rebin-blue-hover" />
          <span className="sr-only">Log out</span>
        </button>
      </nav>
    </section>
  );
};

export default Navbar;



/*
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Rebin from "../assets/embleem@4x.png";
import { GrHomeRounded } from "react-icons/gr";
import { BiBarChartAlt2 } from "react-icons/bi";
import { LuTrash, LuLogOut } from "react-icons/lu";
import { RiSendPlaneLine } from "react-icons/ri";
import { FaRegUser } from "react-icons/fa";

const Navbar = ({ setIsAuthenticated }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
  };

  return (
    <>
      <section className={`bg-white fixed sticky inset-y-0 h-screen z-10 rounded-r-xl shadow-md transition-transform duration-300 ease-in-out ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} md:translate-x-0`}>
        <nav className="flex flex-col justify-between items-center py-10 w-24 h-full md:w-32 lg:w-40">
          <Link className="w-14 p-3 shadow-md rounded-lg" to="/application">
            <img src={Rebin} alt="Rebin" />
          </Link>
          <div className="flex flex-col items-center gap-y-8 sm:gap-y-16">
            <NavLink to="/application" activeClassName="text-rebin-blue">
              <GrHomeRounded className="w-5 h-auto text-rebin-grey opacity-60 hover:opacity-100 text-rebin-blue-hover" />
            </NavLink>
            <NavLink to="/application/navigation" activeClassName="text-rebin-blue">
              <RiSendPlaneLine className="w-5 h-auto text-rebin-grey opacity-60 hover:opacity-100 text-rebin-blue-hover" />
            </NavLink>
            <NavLink to="/application/overview" activeClassName="text-rebin-blue">
              <LuTrash className="w-5 h-auto text-rebin-grey opacity-60 hover:opacity-100 text-rebin-blue-hover" />
            </NavLink>
            <NavLink to="/application/statistics" activeClassName="text-rebin-blue">
              <BiBarChartAlt2 className="w-5 h-auto text-rebin-grey opacity-60 hover:opacity-100 text-rebin-blue-hover" />
            </NavLink>
            <NavLink to="/application/settings" activeClassName="text-rebin-blue">
              <FaRegUser className="w-5 h-auto text-rebin-grey opacity-60 hover:opacity-100 text-rebin-blue-hover" />
            </NavLink>
          </div>
          <button onClick={handleLogout} className="text-rebin-blue hover:text-rebin-blue-hover focus:outline-none">
            <LuLogOut className="w-5 h-auto text-rebin-grey opacity-60 hover:opacity-100 text-rebin-blue-hover" />
            <span className="sr-only">Log out</span>
          </button>
        </nav>
      </section>

      <button 
        className="fixed top-4 left-4 z-20 md:hidden"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <GrHomeRounded className="w-6 h-6 text-rebin-grey" />
      </button>
    </>
  );
};

export default Navbar;
*/

